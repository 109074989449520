import React from 'react'
import PageAllowedToRoles from '../../app/wrappers/PageAllowedToRoles'
import OrderProductSalesDashbaord from '../../components/mycomponents/Dashboard/OrderProductSalesDashbaord'
import SalesQualityAnalysisDashboard from '../../components/mycomponents/Dashboard/SalesQualityAnalysisDashboard'

export default function WebsiteDashboardPage() {
  return (
    <PageAllowedToRoles allowedRoles={['superadmin', 'admin', 'manager', 'operations']}>
      <OrderProductSalesDashbaord />
      <SalesQualityAnalysisDashboard />
    </PageAllowedToRoles>
  )
}
