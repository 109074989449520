import React, { useCallback, useEffect, useState } from 'react'
import MyDatesPicker from '../../mini/MyDatesPicker'
import { Dropdown } from 'primereact/dropdown'
import { MultiSelect } from 'primereact/multiselect'
import { useGlobalContext } from '../../../context'
import { assignDates, dateFilters } from '../../../functions/myDateFns'
import { Knob } from 'primereact/knob'
import { getSalesQualityScore } from '../../../api/dashboard'

export default function SalesQualityAnalysisDashboard() {
  const { user } = useGlobalContext()
  const [dateCode, setDateCode] = useState(dateFilters[1].code)
  const [dateRange, setDateRange] = useState(null)
  const [dateFilter, setDateFilter] = useState(assignDates(dateFilters[1].code))
  const [filterUsers, setFilterUsers] = useState([])
  const [value, setValue] = useState(0)
  const fetchSalesQualityScore = useCallback(async () => {
    const res = await getSalesQualityScore({
      dateFrom: dateFilter.dateFrom,
      dateTo: dateFilter.dateTo,
      filterUsers,
    })
    if (res) {
      if (res.status === 200) {
        setValue(res.data.record.saleQualityScore || 0)
      }
    }
  }, [dateFilter.dateFrom, dateFilter.dateTo, filterUsers])
  useEffect(() => {
    fetchSalesQualityScore()
  }, [fetchSalesQualityScore])

  return (
    <div className="p-grid">
      <div className="p-col-12 w-full p-my-2 p-d-flex gap-1 p-ai-center">
        <h3 className="p-my-0">Sales Quality Analysis</h3>
      </div>
      <div className="w-full">
        <div className="p-d-flex p-flex-wrap gap-1 p-mb-2">
          <div>
            <MyDatesPicker
              code={dateCode}
              setCode={setDateCode}
              rangeDates={dateRange}
              setRangeDates={setDateRange}
              filterDates={dateFilter}
              setFilterDates={setDateFilter}
            />
          </div>
          {['superadmin', 'admin', 'manager', 'operations', 'teamlead'].includes(user.role) && (
            <MultiSelect
              value={filterUsers}
              onChange={(e) => setFilterUsers(e.value)}
              placeholder="Select Agent, Teamlead"
              options={
                user.role === 'teamlead'
                  ? user.members.filter((i) => ['agent'].includes(i.role))
                  : user.members.filter((i) => ['teamlead', 'agent'].includes(i.role))
              }
              optionLabel="username"
              optionValue="id"
              filter
              filterBy="username,role"
              filterPlaceholder="Search..."
              maxSelectedLabels={2}
            />
          )}
        </div>
        <div className="p-d-flex p-jc-center p-ai-center h-full">
          <Knob value={value} min={0} max={7} size={250} onChange={(e) => setValue(value)} />
        </div>
      </div>
    </div>
  )
}
