import React, { useEffect, useState } from 'react'
import { useGlobalContext } from '../../context'

import PageAllowedToRoles from '../../app/wrappers/PageAllowedToRoles'
import GrowthChartDashboard from '../../components/mycomponents/Dashboard/GrowthChartDashboard'
import LeaderboardDashboard from '../../components/mycomponents/Dashboard/LeaderboardDashboard'
import SalesCardsDashboard from '../../components/mycomponents/Dashboard/SalesCardsDashboard'
import TargetsDashbaord from '../../components/mycomponents/Dashboard/TargetsDashbaord'
import OrderProductSalesDashbaord from '../../components/mycomponents/Dashboard/OrderProductSalesDashbaord'
import SalesQualityAnalysisDashboard from '../../components/mycomponents/Dashboard/SalesQualityAnalysisDashboard'

export default function Dashboard() {
  const { user } = useGlobalContext()
  const [greeting, setGreeting] = useState('Greetings')
  useEffect(() => {
    const currentTime = new Date()
    if (currentTime.getHours() < 12) setGreeting('Good Morning')
    else if (currentTime.getHours() < 17) setGreeting('Good Afternoon')
    else setGreeting('Good Evening')
  }, [])

  return (
    <PageAllowedToRoles allowedRoles={['superadmin', 'admin', 'manager', 'teamlead', 'agent']}>
      <h3>
        {greeting}, {user?.username}!
      </h3>
      <div id="salesboard">
        <SalesCardsDashboard />
      </div>
      <div id="targets">
        <TargetsDashbaord />
      </div>
      <div id="leaderboard">
        <LeaderboardDashboard />
      </div>
      <div id="growth-chart">
        <GrowthChartDashboard />
      </div>
      <div id="product-sales-chart">
        <OrderProductSalesDashbaord />
      </div>
      <div id="sales-quality-analysis">
        <SalesQualityAnalysisDashboard />
      </div>
    </PageAllowedToRoles>
  )
}
