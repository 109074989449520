import { Button } from 'primereact/button'
import { Chart } from 'primereact/chart'
import { Checkbox } from 'primereact/checkbox'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { MultiSelect } from 'primereact/multiselect'
import React, { useCallback, useEffect, useState } from 'react'
import { getGrowthChartData } from '../../../api'
import { useGlobalContext } from '../../../context'
import { assignDates, dateFilters } from '../../../functions/myDateFns'
import MyDatesPicker from '../../mini/MyDatesPicker'
import SalesQualityAnalysisDashboard from './SalesQualityAnalysisDashboard'

export default function GrowthChartDashboard() {
  const {
    user,
    colorScheme,
    agents,
    leadSource: ctx_leadSource,
    allStatusGroupsOnly: ctx_allStatusGroupsOnly,
  } = useGlobalContext()

  // GROWTH CHART
  const applyChangeInChart = ({ isAmount, theme, toBeCompareUser }) => {
    const options = {
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        tooltip: {
          callbacks: {
            label: (context) => {
              let val = ''
              if (!isAmount) {
                val = `${context.dataset.label}: ${context.formattedValue} ${
                  context.formattedValue <= 1 ? 'Order' : 'Orders'
                }`
              } else {
                val = `${context.dataset.label}: $${context.formattedValue}`
              }
              if (toBeCompareUser && context.dataset.agent.id !== toBeCompareUser) {
                const idx = context.dataIndex
                let diff = context.dataset.diffData[idx]
                val += ` | ${diff >= 0 ? '+' : ''}${diff}`
              }

              return val
            },
          },
        },
        legend: {
          labels: {
            color: theme === 'light' ? '#A4A4A4' : '#E4E1E1',
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: theme === 'light' ? '#A4A4A4' : '#E4E1E1',
          },
          // grid line should be dashed

          grid: {
            display: false,
            // color: theme === 'light' ? '#D3D3D3' : '#696363',
            // borderDash: [5, 5],
          },
        },
        y: {
          ticks: {
            color: theme === 'light' ? '#A4A4A4' : '#E4E1E1',
            // write relavent code if y-axis is amount in dollars or not
            callback: function (value) {
              return isAmount ? `$${value}` : value
            },
          },
          grid: {
            color: theme === 'light' ? '#F3F1F1' : '#696363',
          },
        },
      },
    }
    return options
  }
  const fetchGrowthData = useCallback(
    async ({
      setData,
      setOption,
      setMeta,
      chartType,
      dateFrom,
      dateTo,
      leads,
      tension,
      isAmount,
      orderStatus,
      users = [],
      isCompare = false,
      theme,
    }) => {
      // check if user is logged in
      if (!user) return
      // check if chart type is selected
      if (!chartType) return
      // check if date range is selected
      if (!dateFrom || !dateTo) return
      // filter null users
      users = users.filter((user) => user)
      // check if user is agent and if so, set user id as users array
      if (user.role === 'agent') {
        users = [user.id]
      }
      // check if user is manager and if so, set user team members as users array if users array is empty
      if (['teamlead'].includes(user.role) && (!users || !users.length)) {
        users = user.members.map((member) => member.id)
      }
      // check if user is not admin and if so, return if users array is empty since admin can view all users
      if (!['superadmin', 'admin', 'manager'].includes(user.role) && !users.length) return
      setOption(
        applyChangeInChart({
          isAmount,
          theme,
          toBeCompareUser: isCompare ? users[0] : null,
        })
      )
      setData({
        labels: [],
        datasets: [],
      })
      const res = await getGrowthChartData({
        chartType,
        dateFrom,
        dateTo,
        leads,
        tension,
        isAmount,
        orderStatus,
        users,
        isCompare,
        theme,
      })
      if (res) {
        if (res.status === 200) {
          // set growth chart data
          setData(res.data.chartData)
          // set growth chart meta like total orders, total amount, etc
          setMeta(res.data.meta)
        }
      }
    },
    [user]
  )

  // GROWTH CHART AGENT SALES
  const [gcAgentSalesCompareDialogVisible, setGcAgentSalesCompareDialogVisible] = useState(false)
  const [gcAgentSalesDateCode, setGcAgentSalesDateCode] = useState(dateFilters[3].code)

  const [gcAgentSalesType, setGcAgentSalesType] = useState(ctx_allStatusGroupsOnly[1].value)
  const [gcAgentSalesLeadsFrom, setGcAgentSalesLeadsFrom] = useState([])
  const [gcAgentSalesDateRange, setGcAgentSalesDateRange] = useState(null)

  const [gcAgentSalesFilterDates, setGcAgentSalesFilterDates] = useState(
    assignDates(dateFilters[3].code)
  )
  const [gcAgentSalesIsAmount, setGcAgentSalesIsAmount] = useState(true)
  const [_gcAgentSalesToCompared, _setGcAgentSalesToCompared] = useState(null)
  const [gcAgentSalesToCompared, setGcAgentSalesToCompared] = useState(null)
  const [_gcAgentSalesComparedWith, _setGcAgentSalesComparedWith] = useState([])
  const [gcAgentSalesComparedWith, setGcAgentSalesComparedWith] = useState([])
  const emptyGcData = {
    labels: [],
    datasets: [],
  }
  const [gcAgentSalesData, setGcAgentSalesData] = useState(emptyGcData)
  const [gcAgentSalesOption, setGcAgentSalesOption] = useState({})
  const [gcAgentMeta, setGcAgentMeta] = useState({})

  useEffect(() => {
    fetchGrowthData({
      setData: setGcAgentSalesData,
      setOption: setGcAgentSalesOption,
      setMeta: setGcAgentMeta,
      chartType: 'agents',
      dateFrom: gcAgentSalesFilterDates.dateFrom,
      dateTo: gcAgentSalesFilterDates.dateTo,
      leads: gcAgentSalesLeadsFrom,
      tension: 0.3,
      isAmount: gcAgentSalesIsAmount,
      orderStatus: gcAgentSalesType,
      users: [gcAgentSalesToCompared, ...gcAgentSalesComparedWith],
      isCompare: gcAgentSalesComparedWith.length > 0,
      theme: colorScheme,
    })
  }, [
    fetchGrowthData,
    gcAgentSalesFilterDates,
    gcAgentSalesLeadsFrom,
    gcAgentSalesIsAmount,
    gcAgentSalesType,
    colorScheme,
    gcAgentSalesToCompared,
    gcAgentSalesComparedWith,
  ])
  // GRWOTH CHART LEAD SALES
  const [gcLeadSalesDateCode, setGcLeadSalesDateCode] = useState(dateFilters[3].code)
  const [gcLeadSalesDateRange, setGcLeadSalesDateRange] = useState(null)
  const [gcLeadSalesFilterDates, setGcLeadSalesFilterDates] = useState(
    assignDates(dateFilters[3].code)
  )
  const [gcLeadSalesIsAmount, setGcLeadSalesIsAmount] = useState(true)
  const [gcLeadSalesData, setGcLeadSalesData] = useState(emptyGcData)
  const [gcLeadSalesOption, setGcLeadSalesOption] = useState({})
  const [gcLeadSalesLeadsFrom, setGcLeadSalesLeadsFrom] = useState(null)
  const [gcLeadSalesType, setGcLeadSalesType] = useState(ctx_allStatusGroupsOnly[1].value)
  const [gcLeadMeta, setGcLeadMeta] = useState({})
  useEffect(
    () =>
      fetchGrowthData({
        setData: setGcLeadSalesData,
        setOption: setGcLeadSalesOption,
        setMeta: setGcLeadMeta,
        chartType: 'leads',
        dateFrom: gcLeadSalesFilterDates.dateFrom,
        dateTo: gcLeadSalesFilterDates.dateTo,
        leads: gcLeadSalesLeadsFrom,
        tension: 0.3,
        isAmount: gcLeadSalesIsAmount,
        orderStatus: gcLeadSalesType,

        theme: colorScheme,
      }),
    [
      fetchGrowthData,
      gcLeadSalesFilterDates,
      gcLeadSalesLeadsFrom,
      gcLeadSalesIsAmount,
      colorScheme,
      gcLeadSalesType,
    ]
  )

  // GRWOTH CHART ORDER SALES
  const [gcOrderSalesDateCode, setGcOrderSalesDateCode] = useState(dateFilters[3].code)
  const [gcOrderSalesDateRange, setGcOrderSalesDateRange] = useState(null)
  const [gcOrderSalesFilterDates, setGcOrderSalesFilterDates] = useState(
    assignDates(dateFilters[3].code)
  )
  const [gcOrderSalesIsAmount, setGcOrderSalesIsAmount] = useState(true)
  const [gcOrderMeta, setGcOrderMeta] = useState({})
  const [gcOrderSalesData, setGcOrderSalesData] = useState(emptyGcData)
  const [gcOrderSalesOption, setGcOrderSalesOption] = useState({})
  const [gcOrderSalesLeadsFrom, setGcOrderSalesLeadsFrom] = useState(null)
  const [gcOrderSalesType, setGcOrderSalesType] = useState(ctx_allStatusGroupsOnly[1].value)
  useEffect(
    () =>
      fetchGrowthData({
        setData: setGcOrderSalesData,
        setOption: setGcOrderSalesOption,
        setMeta: setGcOrderMeta,
        chartType: 'orderStatus',
        dateFrom: gcOrderSalesFilterDates.dateFrom,
        dateTo: gcOrderSalesFilterDates.dateTo,
        leads: gcOrderSalesLeadsFrom,
        tension: 0.3,
        isAmount: gcOrderSalesIsAmount,
        orderStatus: gcOrderSalesType,

        theme: colorScheme,
      }),
    [
      fetchGrowthData,
      gcOrderSalesFilterDates,
      gcOrderSalesLeadsFrom,
      gcOrderSalesIsAmount,
      colorScheme,
      gcOrderSalesType,
    ]
  )

  return (
    <div>
      <h3 className="p-my-0">Growth Chart</h3>
      <div className="p-grid p-my-3">
        <div className="p-col-12">
          <div className="card">
            <h5>Main Chart</h5>
            <div className="p-d-flex p-flex-wrap gap-1">
              <MultiSelect
                maxSelectedLabels={3}
                value={gcAgentSalesLeadsFrom}
                onChange={(e) => setGcAgentSalesLeadsFrom(e.value)}
                placeholder="Lead Source"
                options={ctx_leadSource}
                optionLabel="name"
                optionValue="value"
              />
              <Dropdown
                value={gcAgentSalesType}
                onChange={(e) => setGcAgentSalesType(e.value)}
                placeholder="Order Status"
                options={ctx_allStatusGroupsOnly}
                optionLabel="name"
                optionValue="value"
              />
              <div className="max-w-30">
                <MyDatesPicker
                  code={gcAgentSalesDateCode}
                  setCode={setGcAgentSalesDateCode}
                  rangeDates={gcAgentSalesDateRange}
                  filterDates={gcAgentSalesFilterDates}
                  setRangeDates={setGcAgentSalesDateRange}
                  setFilterDates={setGcAgentSalesFilterDates}
                />
              </div>

              <Button
                label="Compare"
                className="p-button-secondary"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => setGcAgentSalesCompareDialogVisible(true)}
              />
            </div>
            <h5 className="p-d-flex gap-2 p-jc-center">
              <span>{gcAgentMeta?.chartTitle}</span>
              <span className="p-d-flex gap-1by2">
                <Checkbox
                  inputId="salesInAmountCheck"
                  onChange={(e) => setGcAgentSalesIsAmount(e.checked)}
                  checked={gcAgentSalesIsAmount}
                />
                <label htmlFor="salesInAmountCheck">Amount</label>
              </span>
            </h5>
            <h6 className="p-d-flex gap-1 p-jc-center">
              <span>Order Amount: ${gcAgentMeta?.orderTotal}</span>
              <span>Order Count: {gcAgentMeta?.orderCount}</span>
              <span>Date: {gcAgentMeta?.chartDateTitle}</span>
            </h6>
            <Chart height={400} type="line" data={gcAgentSalesData} options={gcAgentSalesOption} />
          </div>
        </div>
        {/* <div className="p-col-3">
          <div className="card">
            <SalesQualityAnalysisDashboard />
          </div>
        </div> */}
        <div className="p-col-12 p-lg-6">
          <div className="card">
            <h5>Leads Chart</h5>
            <div className="p-d-flex p-flex-wrap gap-1">
              <MultiSelect
                maxSelectedLabels={3}
                value={gcLeadSalesLeadsFrom}
                onChange={(e) => setGcLeadSalesLeadsFrom(e.value)}
                placeholder="Lead Source"
                options={ctx_leadSource}
                optionLabel="name"
                optionValue="value"
              />
              <Dropdown
                value={gcLeadSalesType}
                onChange={(e) => setGcLeadSalesType(e.value)}
                placeholder="Order Status"
                options={ctx_allStatusGroupsOnly}
                optionLabel="name"
                optionValue="value"
              />
              <div className="max-w-30">
                <MyDatesPicker
                  code={gcLeadSalesDateCode}
                  setCode={setGcLeadSalesDateCode}
                  rangeDates={gcLeadSalesDateRange}
                  filterDates={gcLeadSalesFilterDates}
                  setRangeDates={setGcLeadSalesDateRange}
                  setFilterDates={setGcLeadSalesFilterDates}
                />
              </div>
            </div>
            <h5 className="p-d-flex gap-2 p-jc-center">
              <span>{gcLeadMeta?.chartTitle}</span>
              <span className="p-d-flex gap-1by2">
                <Checkbox
                  inputId="salesInAmountCheck"
                  onChange={(e) => setGcLeadSalesIsAmount(e.checked)}
                  checked={gcLeadSalesIsAmount}
                />
                <label htmlFor="salesInAmountCheck">Amount</label>
              </span>
            </h5>
            <h6 className="p-d-flex gap-1 p-jc-center">
              <span>Order Amount: ${gcLeadMeta?.orderTotal}</span>
              <span>Order Count: {gcLeadMeta?.orderCount}</span>
              <span>Date: {gcLeadMeta?.chartDateTitle}</span>
            </h6>
            <Chart height={300} type="line" data={gcLeadSalesData} options={gcLeadSalesOption} />
          </div>
        </div>
        <div className="p-col-12 p-lg-6">
          <div className="card">
            <h5>Orders Chart</h5>
            <div className="p-d-flex p-flex-wrap gap-1">
              <MultiSelect
                maxSelectedLabels={3}
                value={gcOrderSalesLeadsFrom}
                onChange={(e) => setGcOrderSalesLeadsFrom(e.value)}
                placeholder="Lead Source"
                options={ctx_leadSource}
                optionLabel="name"
                optionValue="value"
              />
              <Dropdown
                value={gcOrderSalesType}
                onChange={(e) => setGcOrderSalesType(e.value)}
                placeholder="Order Status"
                options={ctx_allStatusGroupsOnly}
                optionLabel="name"
                optionValue="value"
              />
              <div className="max-w-30">
                <MyDatesPicker
                  code={gcOrderSalesDateCode}
                  setCode={setGcOrderSalesDateCode}
                  rangeDates={gcOrderSalesDateRange}
                  filterDates={gcOrderSalesFilterDates}
                  setRangeDates={setGcOrderSalesDateRange}
                  setFilterDates={setGcOrderSalesFilterDates}
                />
              </div>
            </div>
            <h5 className="p-d-flex gap-2 p-jc-center">
              <span>{gcOrderMeta?.chartTitle}</span>
              <span className="p-d-flex gap-1by2">
                <Checkbox
                  inputId="salesInAmountCheck"
                  onChange={(e) => setGcOrderSalesIsAmount(e.checked)}
                  checked={gcOrderSalesIsAmount}
                />
                <label htmlFor="salesInAmountCheck">Amount</label>
              </span>
            </h5>
            <h6 className="p-d-flex gap-1 p-jc-center">
              <span>Order Amount: ${gcOrderMeta?.orderTotal}</span>
              <span>Order Count: {gcOrderMeta?.orderCount}</span>
              <span>Date: {gcOrderMeta?.chartDateTitle}</span>
            </h6>
            <Chart height={300} type="line" data={gcOrderSalesData} options={gcOrderSalesOption} />
          </div>
        </div>
      </div>

      <Dialog
        onHide={() => setGcAgentSalesCompareDialogVisible(false)}
        visible={gcAgentSalesCompareDialogVisible}
        className="w-full max-w-50"
        header={
          <h5 className="text-blue p-text-center p-text-bold p-m-0  ">
            <i className="pi pi-sort-alt p-mr-2" style={{ fontSize: '1rem' }}></i>
            <span className="p-mr-2">Make Comparison</span>
          </h5>
        }
        footer={
          <div className="p-d-flex p-jc-center">
            <Button
              id="fetchGcAgent"
              className="w-full"
              label="Done"
              disabled={
                // disable the button if the to be compared is empty or the compared with is empty
                (_gcAgentSalesToCompared &&
                  (!_gcAgentSalesComparedWith || !_gcAgentSalesComparedWith.length)) ||
                (!_gcAgentSalesToCompared &&
                  _gcAgentSalesComparedWith &&
                  _gcAgentSalesComparedWith.length)
              }
              onClick={() => {
                // set the compared with to the to be compared
                setGcAgentSalesToCompared(_gcAgentSalesToCompared || null) // set the to be compared to null if it is empty
                setGcAgentSalesComparedWith(_gcAgentSalesComparedWith || []) // set the compared with to empty array if it is empty
                setGcAgentSalesCompareDialogVisible(false)
              }}
            />
          </div>
        }
      >
        <div className="p-d-flex gap-2 w-full">
          <div className="p-d-flex p-flex-column gap-1by2 flex-1">
            <label htmlFor="gcAgentSalesToCompared" className="p-text-bold">
              To Be Compared
            </label>
            {['superadmin', 'admin', 'manager'].includes(user.role) && (
              <Dropdown
                inputId="gcAgentSalesToCompared"
                value={_gcAgentSalesToCompared}
                onChange={(e) => _setGcAgentSalesToCompared(e.value)}
                placeholder="Select Agent"
                options={agents.filter(
                  (agent) =>
                    ['agent', 'teamlead'].includes(agent.role) &&
                    !_gcAgentSalesComparedWith?.includes(agent.id)
                )}
                optionLabel="username"
                optionValue="id"
                filter
                filterBy="username"
                filterPlaceholder="Search"
                showClear
              />
            )}
            {['teamlead'].includes(user.role) && (
              <Dropdown
                inputId="gcAgentSalesToCompared"
                value={_gcAgentSalesToCompared}
                onChange={(e) => _setGcAgentSalesToCompared(e.value)}
                placeholder="Select Agent"
                options={user.members.filter(
                  (agent) => !_gcAgentSalesComparedWith?.includes(agent.id)
                )}
                optionLabel="username"
                optionValue="id"
                filter
                filterBy="username"
                filterPlaceholder="Search"
                showClear
              />
            )}
          </div>
          <div className="p-d-flex p-flex-column gap-1by2 flex-1">
            <label htmlFor="gcAgentSalesComparedWith" className="p-text-bold">
              Compared With
            </label>
            {['superadmin', 'admin', 'manager'].includes(user.role) && (
              <MultiSelect
                display="chip"
                inputId="gcAgentSalesComparedWith"
                value={_gcAgentSalesComparedWith}
                onChange={(e) => _setGcAgentSalesComparedWith(e.value)}
                options={agents.filter(
                  (agent) =>
                    agent.id !== _gcAgentSalesToCompared &&
                    ['agent', 'teamlead'].includes(agent.role)
                )}
                placeholder="Select Agents"
                optionLabel="username"
                optionValue="id"
                filter
                filterBy="username"
                filterPlaceholder="Search"
                showClear
              />
            )}
            {['teamlead'].includes(user.role) && (
              <MultiSelect
                display="chip"
                inputId="gcAgentSalesComparedWith"
                value={_gcAgentSalesComparedWith}
                onChange={(e) => _setGcAgentSalesComparedWith(e.value)}
                options={user.members.filter((agent) => agent.id !== _gcAgentSalesToCompared)}
                placeholder="Select Agents"
                optionLabel="username"
                optionValue="id"
                filter
                filterBy="username"
                filterPlaceholder="Search"
                showClear
              />
            )}
          </div>
        </div>
      </Dialog>
    </div>
  )
}
