import axios from 'axios'
import endpoints from './endpoints'

export const getCustomerEnquires = async ({ dateFrom, dateTo }) => {
  try {
    const res = await axios.get(endpoints.customer.inquires, {
      params: { dateFrom, dateTo },
    })
    return res
  } catch (error) {
    return error.response
  }
}
export const getCustomerEnquiry = async (id) => {
  try {
    const res = await axios.get(endpoints.customer.inquires + id)
    return res
  } catch (error) {
    return error.response
  }
}
export const patchCustomerEnquire = async (id, payload) => {
  try {
    return await axios.patch(endpoints.customer.inquires + id, payload)
  } catch (error) {
    return error.response
  }
}

// analysis
export const getEnquiryAnalysis = async ({ type, dateFrom, dateTo, selectedAgents }) => {
  try {
    return await axios.get(endpoints.customer.inquires_analysis, {
      params: { type, dateFrom, dateTo, selectedAgents },
    })
  } catch (error) {
    return error.response
  }
}
export const getCustomerEnquireFeedbacks = async (id) => {
  try {
    const res = await axios.get(endpoints.customer.inquire_feedback + id)
    return res
  } catch (error) {
    return error.response
  }
}
export const getCustomerEnquireReplies = async (id) => {
  try {
    const res = await axios.get(endpoints.customer.inquire_reply + id)
    return res
  } catch (error) {
    return error.response
  }
}
export const addCustomerEnquireFeedback = async (id, payload) => {
  try {
    const res = await axios.post(endpoints.customer.inquire_feedback + id, payload)
    return res
  } catch (error) {
    return error.response
  }
}
export const addCustomerEnquireReply = async (id, payload) => {
  try {
    const res = await axios.post(endpoints.customer.inquire_reply + id, payload)
    return res
  } catch (error) {
    return error.response
  }
}

// blacklist
export const getBlacklistCustomers = async (limit) => {
  try {
    return await axios.get(endpoints.customer.blacklist, {
      params: {
        limit,
      },
    })
  } catch (error) {
    return error.response
  }
}
export const postBlacklistCustomer = async (payload) => {
  try {
    return await axios.post(endpoints.customer.blacklist, payload)
  } catch (error) {
    return error.response
  }
}
export const getBlacklistCustomer = async (id) => {
  try {
    return await axios.get(endpoints.customer.blacklist + id)
  } catch (error) {
    return error.response
  }
}
export const patchBlacklistCustomerStatus = async (id) => {
  try {
    return await axios.patch(endpoints.customer.blacklist + id)
  } catch (error) {
    return error.response
  }
}
export const deleteBlacklistCustomer = async (id) => {
  try {
    return await axios.delete(endpoints.customer.blacklist + id)
  } catch (error) {
    return error.response
  }
}
export const putBlacklistCustomerRemoveRequest = async (id, payload) => {
  try {
    return await axios.put(endpoints.customer.blacklist + id, payload)
  } catch (error) {
    return error.response
  }
}

// dnd
export const getAllDNDRecords = async () => {
  try {
    return await axios.get(endpoints.customer.dnd)
  } catch (error) {
    return error.response
  }
}

export const getDNDRecordById = async (id) => {
  try {
    return await axios.get(endpoints.customer.dnd + '/' + id)
  } catch (error) {
    return error.response
  }
}

export const postDNDRecord = async (email, phone, dndTillDate, note) => {
  try {
    return await axios.post(endpoints.customer.dnd, {
      email,
      phone,
      dndTillDate,
      note,
    })
  } catch (error) {
    return error.response
  }
}

export const patchDNDRecord = async (id, email, phone, dndTillDate, note) => {
  try {
    return await axios.patch(endpoints.customer.dnd + '/' + id, {
      email,
      phone,
      dndTillDate,
      note,
    })
  } catch (error) {
    return error.response
  }
}

export const deleteDNDRecord = async (id, password) => {
  const data = JSON.stringify({ password })
  try {
    return await axios.delete(endpoints.customer.dnd + '/' + id, {
      data,
    })
  } catch (error) {
    return error.response
  }
}
