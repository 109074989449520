import React, { useEffect, useState, useCallback } from 'react'
import PageAllowedToRoles from '../../app/wrappers/PageAllowedToRoles'
import { DataTable } from 'primereact/datatable'
import { Toolbar } from 'primereact/toolbar'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import {
  deleteCoupon,
  getCouponAnalytics,
  getCoupons,
  getPaymentMethods,
  searchQuantity,
  searchStrength,
} from '../../api'
import { useGlobalContext } from '../../context'
import { Toast } from 'primereact/toast'
import { format, isValid, set } from 'date-fns'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import ChangeHistorySidebar from '../../components/mycomponents/ChangeHistorySidebar'
import AddCoupon from '../../components/mycomponents/Coupons/AddCoupon'
import EditCoupon from '../../components/mycomponents/Coupons/EditCoupon'
import { Dropdown } from 'primereact/dropdown'
import { FilterMatchMode } from 'primereact/api'
import { InputText } from 'primereact/inputtext'
import ConfirmationDialogWithInput from '../../components/mycomponents/Dialog/ConfirmationDialogWithInput'
import { Password } from 'primereact/password'
import { TabPanel, TabView } from 'primereact/tabview'

function CouponsPage() {
  const emptyFilterObject = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    createdBy: { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    discountType: { value: null, matchMode: FilterMatchMode.EQUALS },
  }

  const discountTypeOptions = ['Price', 'Percentage']
  const typeOptions = ['All', 'Category', 'Product', 'Strength', 'Quantity', 'Payment Method']
  const rankOptions = [1, 2, 3, 4, 5]

  const statusOptions = [
    {
      label: 'Active',
      value: 1,
    },
    {
      label: 'Inactive',
      value: 0,
    },
  ]

  const [globalFilters, setGlobalFilters] = useState(emptyFilterObject)
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [visibleCouponDialog, setVisibleCouponDialog] = useState(false)
  const [visibleEditCouponDialog, setVisibleEditCouponDialog] = useState(false)
  const [coupons, setCoupons] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [analyticsTableLoading, setAnalyticsTableLoading] = useState(false)
  const { toast, user } = useGlobalContext()
  const [password, setPassword] = useState('')
  const [passwordConfirmationDialog, setPasswordConfirmationDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [deleteCouponId, setDeleteCouponId] = useState(null)
  const [couponAnalytics, setCouponAnalytics] = useState([])
  const fetchStrength = async (code, setStrength) => {
    if (!code) return
    const res = await searchStrength(code)
    if (res && res.status === 200) {
      setStrength(
        res.data
          .filter((strength) => strength.strengthStatus === 'active' && strength)
          .map((strength) => ({
            name: strength.strengthName,
            code: strength.strengthCode,
            isStockOut: strength.isStockOut,
          }))
      )
    }
  }

  const fetchQuantity = async (code, setQuantity) => {
    if (!code) return
    const res = await searchQuantity(code)
    if (res && res.status === 200) {
      setQuantity(
        res.data
          .filter((quantity) => quantity.qtyStatus === 'active' && quantity)
          .map((quantity) => ({
            name: quantity.stringQty,
            code: quantity.quantityCode,
            isStockOut: quantity.isStockOut,
          }))
      )
    }
  }

  // Fetch Coupon analytics
  const fetchCouponAnalytics = useCallback(async () => {
    setAnalyticsTableLoading(true)
    const res = await getCouponAnalytics()
    if (res) {
      setAnalyticsTableLoading(false)
      if (res.status === 200) {
        setCouponAnalytics(res.data)
      }
    }
  }, [])

  // Fetch coupon records
  const fetchCoupons = async () => {
    setTableLoading(true)
    const res = await getCoupons()
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        setCoupons(res.data)
      }
    }
  }

  useEffect(() => {
    fetchCoupons()
    fetchCouponAnalytics()
  }, [])

  const onPasswordSubmitConfirmation = async () => {
    let id = deleteCouponId
    if (!id) return
    setTableLoading(true)
    setLoading(true)
    const res = await deleteCoupon(id, password)
    if (res) {
      setTableLoading(false)
      setLoading(false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: res.data.message,
        })
        const updatedCouponRecords = coupons.filter((coupon) => coupon.id !== id)
        setCoupons(updatedCouponRecords)
        onConfirmDialogHide()
      }
    }
  }

  const onChangeFilter = (setter, value, target) => {
    setter((ps) => ({ ...ps, [target]: { ...ps[target], value } }))
  }

  const onConfirmDialogHide = () => {
    setPasswordConfirmationDialog(false)
    setPassword('')
  }

  const ActionBody = (rd) => {
    return (
      <div className="p-d-flex gap-1by2 p-jc-center">
        <Link to={`?id=${rd?.id}`}>
          <Button icon="pi pi-pencil" className="p-button p-button-info" />
        </Link>
        <Button
          icon="pi pi-trash"
          className="p-button p-button-danger"
          onClick={() => {
            setPasswordConfirmationDialog(true)
            setDeleteCouponId(rd.id)
          }}
        />
        <Link to={`?history=${rd.id}`}>
          <Button
            tooltip="History"
            tooltipOptions={{ position: 'bottom' }}
            icon="pi pi-clock"
            className="p-button p-button-help"
          />
        </Link>
      </div>
    )
  }

  const validateDate = (value) => {
    const date = new Date(value)
    return <>{isValid(date) ? format(date, 'PPp') : 'Invalid date'}</>
  }

  const DataTableHeader = () => {
    return (
      <div className="p-d-flex p-flex-wrap gap-1by2 p-jc-end p-mb-2">
        <div className=" p-d-flex p-ai-center">
          <Dropdown
            options={discountTypeOptions}
            placeholder="Select Type"
            value={globalFilters.discountType.value}
            onChange={(e) => {
              onChangeFilter(setGlobalFilters, e.value, 'discountType')
            }}
            showClear
          />
        </div>
        <div>
          <Dropdown
            placeholder="Select Creator"
            options={user?.members.filter((i) =>
              ['operations', 'admin', 'manager'].includes(i.role)
            )}
            value={globalFilters.createdBy.value}
            optionLabel="username"
            optionValue="id"
            showClear
            filter
            filterBy="username"
            onChange={(e) => {
              onChangeFilter(setGlobalFilters, e.value, 'createdBy')
            }}
          ></Dropdown>
        </div>
        <div className=" p-d-flex p-ai-center">
          <Dropdown
            options={statusOptions}
            placeholder="Select Status"
            value={globalFilters.status.value}
            onChange={(e) => {
              onChangeFilter(setGlobalFilters, e.value, 'status')
            }}
            showClear
          />
        </div>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            value={globalFilterValue}
            onChange={(e) => {
              setGlobalFilterValue(e.target.value)
              onChangeFilter(setGlobalFilters, e.target.value, 'global')
            }}
            placeholder="Search..."
          />
        </span>
      </div>
    )
  }

  return (
    <>
      <PageAllowedToRoles allowedRoles={['superadmin', 'admin', 'manager', 'operations']}>
        <div className="card">
          <TabView>
            <TabPanel header="Coupon Management">
              <Toast ref={toast} />
              <Toolbar
                className="p-toolbar p-mb-3 p-flex-wrap gap-1mb-4"
                left={
                  <div className="p-d-flex p-flex-wrap gap-1 p-ai-center">
                    <h4 className="p-m-0">Coupons</h4>
                    <Button
                      onClick={() => setVisibleCouponDialog(true)}
                      label="Add Coupon"
                      icon="pi pi-plus"
                      className="p-button-primary"
                    />
                  </div>
                }
                right={
                  <div>
                    <div className="p-d-flex p-flex-wrap gap-1">
                      <Button icon="pi pi-refresh" onClick={() => fetchCoupons()} />
                    </div>
                  </div>
                }
              ></Toolbar>
              <DataTable
                value={coupons}
                loading={tableLoading}
                filters={globalFilters}
                removableSort
                showGridlines
                dataKey="id"
                paginator
                rows={10}
                rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                header={DataTableHeader}
              >
                <Column header="Id" field="id" sortable style={{ width: '1%' }} />
                <Column header="Code" field="code" style={{ width: '15%' }} />
                <Column header="Discount" field="discount" style={{ width: '5%' }} />
                <Column header="Discount Type" field="discountType" style={{ width: '10%' }} />
                <Column
                  header="Show On Checkout"
                  body={(rd) => <>{rd.isShownOnCheckout ? 'Yes' : 'No'}</>}
                  style={{ width: '10%' }}
                />
                <Column
                  header="Valid From"
                  body={(rd) => validateDate(rd.validFrom)}
                  style={{ width: '12%' }}
                />
                <Column
                  header="Valid To"
                  body={(rd) => validateDate(rd.validTo)}
                  style={{ width: '12%' }}
                />
                <Column
                  header="Status"
                  body={(rd) => <div>{rd.status ? 'Active' : 'Inactive'}</div>}
                  style={{ width: '10%' }}
                />
                <Column
                  header="Created At"
                  body={(rd) => validateDate(rd.createdAt)}
                  style={{ width: '12%' }}
                />
                <Column header="Created By" field="createdBy" style={{ width: '10%' }} />
                <Column header="Action" body={(rd) => ActionBody(rd)} />
              </DataTable>
              <AddCoupon
                visibleCouponDialog={visibleCouponDialog}
                setVisibleCouponDialog={setVisibleCouponDialog}
                setCoupons={setCoupons}
                fetchStrength={fetchStrength}
                fetchQuantity={fetchQuantity}
                discountTypeOptions={discountTypeOptions}
                typeOptions={typeOptions}
                rankOptions={rankOptions}
              />
              <EditCoupon
                visibleEditCouponDialog={visibleEditCouponDialog}
                setVisibleEditCouponDialog={setVisibleEditCouponDialog}
                setTableLoading={setTableLoading}
                setCoupons={setCoupons}
                coupons={coupons}
                fetchStrength={fetchStrength}
                fetchQuantity={fetchQuantity}
                discountTypeOptions={discountTypeOptions}
                typeOptions={typeOptions}
                rankOptions={rankOptions}
              />
            </TabPanel>
            <TabPanel header="Coupon Analytics">
            <Toast ref={toast} />
              <Toolbar
                className="p-toolbar p-mb-3 p-flex-wrap gap-1mb-4"
                left={
                  <div className="p-d-flex p-flex-wrap gap-1 p-ai-center">
                    <h4 className="p-m-0">Coupons Analytics</h4>
                  </div>
                }
                right={
                  <div>
                    <div className="p-d-flex p-flex-wrap gap-1">
                      <Button icon="pi pi-refresh" onClick={
                        fetchCouponAnalytics
                      } />
                    </div>
                  </div>
                }
              ></Toolbar>
              <DataTable
              value={couponAnalytics}
              loading={analyticsTableLoading}
              dataKey="id"
              removableSort
              showGridlines
              paginator
              rows={10}
              rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
              >
                <Column header="Coupon Code" field="couponCode" style={{ width: '50%' }} />
                <Column header="Coupon Usage" field="couponUsage" style={{ width: '50%' }} />
              </DataTable>
            </TabPanel>
          </TabView>
          {/* Confirmation dialog to ask password start*/}
          <ConfirmationDialogWithInput
            onHide={onConfirmDialogHide}
            header={'Confirm Password To Delete'}
            onSubmit={onPasswordSubmitConfirmation}
            visible={passwordConfirmationDialog}
            inputLabel={'Password'}
            customInputs={true}
            loading={loading}
          >
            <div className="">
              <label htmlFor="password" className="">
                Password
              </label>
              <Password
                autoComplete="nope"
                toggleMask
                feedback={false}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-mt-1"
                placeholder="Enter your password"
              />
            </div>
          </ConfirmationDialogWithInput>
          {/* Confirmation dialog to ask password end*/}
          <ChangeHistorySidebar header="Coupon History" historyType="coupon" />
        </div>
      </PageAllowedToRoles>
    </>
  )
}

export default CouponsPage
